<script>
  export default {
    name: 'ArrowButton',
    props: {
      icon: String,
      text: String,
      color: {
        type: String,
        default: "#1E1E1E"
      }
    },
  }
</script>
<template>
  <div :style="{ color: color }" class="button" @click="$emit('click')">
    <v-icon v-if="icon" class="customicon icon">{{ icon }}</v-icon><span class="buttontext">{{ text }}</span><v-icon class="icon arrowicon">$arrow_top_right</v-icon>
  </div>
</template>

<style lang="scss">
  @import '@/assets/sass/theme.scss';

  .button {
    border-top: solid 2px currentColor;
    padding-top: 4px;
    width: 80%;
    max-width: 300px;
    margin-bottom: 40px;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .v-icon {
      font-size: inherit;
      color: inherit;
    }
  }
  
  .customicon {
    vertical-align: -3px;
    margin-right: 5px;

  }
  .arrowicon {
    // float: right;
  }
</style>
