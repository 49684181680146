<script>
import { mapState } from "vuex";
import gsap from "gsap";
import ArrowButton from "@/components/ArrowButton.vue";
import Vuetify from "vuetify";

export default {
  name: "Grid3col",
  props: ["id", "currentProductGroup"],
  components: {
    ArrowButton,
  },

  computed: {
    gridTitle() {
      if (
        this.currentProductGroup.product_layout &&
        this.currentProductGroup.product_layout[0].value
      ) {
        return this.currentProductGroup.product_layout[0].value.grid_title;
      } else {
        return "";
      }
    },
    squares() {
      let products = [];
      if (
        this.currentProductGroup.product_layout &&
        this.currentProductGroup.product_layout[0].value.products
      ) {
        let prods = this.currentProductGroup.product_layout[0].value.products.map(
          (x) => x.value
        );
        for (const x of prods) {
          let product = {
            code: "",
            thumb: "",
            path: "",
          };
          product.path = `/productmodel/${x.name_slug}`;
          product.code = x.code;
          products.push(product);
          let gallery = x.gallery.map((x) => x.value.item_media);
          for (const img of gallery) {
            if (img && img.mime.indexOf("image") !== -1) {
              product.thumb = img.path;
              break;
            }
          }
        }
      }
      return products;
    },
  },
};
</script>

<template>
  <div class="grid grid3col ">
    <div class="gridCard cardGroup grid-transition">
      <div class="cardContent d-flex flex-column">
        <h2 class="gridCardHead">
          {{ gridTitle }}
        </h2>
        <div class="gridInner d-flex flex-column">

          <!-- Standard cols start -->
          <v-row no-gutters class="flex-row align-center">
          <v-col
            v-for="product in squares"
            :key="product.code"
            class="flex-column d-flex col-4 gridCardCol"
            @click="$router.push({ path: product.path })"
          >
            <div class="gridItem d-flex flex-column">
              <div class="gridCardImage">
                <v-img
                  width="100%"
                  height="100%"
                  contain
                  :src="getMedia(product.thumb)"
                >
                  <template v-slot:placeholder>
                    <v-layout fill-height align-center justify-center>
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-layout>
                  </template>
                </v-img>
              </div>
              <ArrowButton icon="" :text="product.code" />
            </div>
          </v-col>
        </v-row>
        <!-- Standard cols End -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/grid.scss";
</style>

<style lang="scss" scoped>
@import "@/assets/sass/colors.scss";

  .gridCardImage {
    .v-image {
      min-height: 60px;
    }
  }

  .productGrid {
      overflow: auto;
  }

  @media (min-width: 960px){
    .gridInner  {
    overflow: auto;
    }
  }

  .productGrid .gridCard .cardContent {
    box-shadow: none;
    border: solid 1px $black;
  }

</style>
