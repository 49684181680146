<script>
import { mapState } from "vuex";
import gsap from "gsap";
import ArrowButton from "@/components/ArrowButton.vue";
import Vuetify from "vuetify";

export default {
  name: "Grid2x2",
  props: ["id", "currentProductGroup"],
  components: {
    ArrowButton,
  },

  computed: {
    squares() {
      let products = [];
      if (
        this.currentProductGroup.product_layout &&
        this.currentProductGroup.product_layout[0].value.products
      ) {
        let prods = this.currentProductGroup.product_layout[0].value.products.map(
          (x) => x.value
        );
        for (const x of prods) {
          let product = {
            code: "",
            thumb: "",
            path: "",
          };
          product.path = `/productmodel/${x.name_slug}`;
          product.code = x.code;
          products.push(product);
          let gallery = x.gallery.map((x) => x.value.item_media);
          for (const img of gallery) {
            if (img && img.mime.indexOf("image") !== -1) {
              product.thumb = img.path;
              break;

            }
          }
        }
      }
      return products;
    },
  },
};
</script>

<template >

  <v-row class="grid grid-2x2" ref="grid" >
    <v-col v-for="(product, i) in squares" :key="product.code" class="col-12 col-md-6 cardCol" :class="`card-${i}`" >
      <div class="gridCard cardIndividual grid-transition" >
        <div class="cardContent" @click="$router.push({ path: product.path })">
          <h2 class="gridCardHead">
            {{ product.code
            }}<v-icon color="white" class="arrowicon">$arrow_top_right</v-icon>
          </h2>

          <div class="gridCardImage">
            <v-img
              width="100%"
              height="100%"
              contain
              :src="getMedia(product.thumb)"
            >
              <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center>
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-layout>
              </template>
            </v-img>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss">
@import "@/assets/sass/grid.scss";
</style>
