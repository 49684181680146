<script>
import { mapState } from "vuex";
import gsap from "gsap";
import ArrowButton from "@/components/ArrowButton.vue";
import Vuetify from "vuetify";

export default {
  name: "Grid2x6",
  props: ["id", "currentProductGroup"],
  components: {
    ArrowButton,
  },
  // watch: {
  //   $route: "init",
  // },
  // async created() {
  //   await this.init();
  // },
  computed: {
    leftCol() {
      if (
        this.currentProductGroup.product_layout &&
        this.currentProductGroup.product_layout[0].value.left_column
      ) {
        return this.currentProductGroup.product_layout[0].value.left_column.map(
          (x) => {
            x.value.thumb = "";
            if (x.value.gallery) {
              x.value.path = `/productmodel/${x.value.name_slug}`;
              let images = x.value.gallery.map((x) => x.value.item_media);
              for (const el of images) {
                if (el && el.mime.indexOf("image") !== -1) {
                  x.value.thumb = el.path;
                  break;
                }
              }
            }  else {
              x.value.thumb = "";
            }
            return x.value;
          }
        );
      }
      return [];
    },
    rightCol() {
      if (
        this.currentProductGroup.product_layout &&
        this.currentProductGroup.product_layout[0].value.right_column
      ) {
        return this.currentProductGroup.product_layout[0].value.right_column.right_column_products.map(
          (x) => {
            x.value.thumb = "";
            if (x.value.gallery) {
              x.value.path = `/productmodel/${x.value.name_slug}`;
              let images = x.value.gallery.map((x) => x.value.item_media);
              for (const el of images) {
                if (el && el.mime.indexOf("image") !== -1) {
                  x.value.thumb = el.path;
                  break;
                }
              }
            }  else {
              x.value.thumb = "";
            }
            return x.value;
          }
        );
      }
      return [];
    },
  }
  }

</script>

<template>
 
        <v-row>
          <!-- Left Product Grid -->
          <v-col class="leftProductGrid col-12 col-md-5">
            <div
              v-for="product in leftCol"
              :key="product.code"
              class="gridCard cardIndividual flex-column d-flex border grid-transition"
              @click="$router.push({ path: product.path })"
            >
              <div class="cardContent">
                <h2 class="gridCardHead">
                  {{ product.code
                  }}<v-icon color="white" class="arrowicon"
                    >$arrow_top_right</v-icon
                  >
                </h2>

                <div class="gridCardImage">
                  <v-img
                    width="100%"
                    height="100%"
                    contain
                    :src="getMedia(product.thumb)"
                  >
                    <template v-slot:placeholder>
                      <v-layout fill-height align-center justify-center>
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-layout>
                    </template>
                  </v-img>
                </div>
              </div>
            </div>
          </v-col>
          <!-- End Left Product Grid -->

          <!-- Right Product Grid -->
          <v-col class="rightProductGrid col-12 col-md-7 grid-transition">
            <div class="gridCard cardGroup">
              <div class="cardContent d-flex flex-column">
                <h2 class="gridCardHead">
                  {{
                    currentProductGroup.product_layout[0].value.right_column
                      .right_col_title
                  }}
                </h2>
                <v-row no-gutters class="flex-row align-center">
                  <v-col
                    v-for="productModel in rightCol"
                    :key="productModel.code"
                    class="flex-column d-flex col-6 gridCardCol"
                    @click="$router.push({ path: productModel.path })"
                  >
                    <div class="gridItem d-flex flex-column">

                      <div class="gridCardImage">
                        <v-img
                          width="100%"
                          height="100%"
                          contain
                          :src="getMedia(productModel.thumb)"
                        >
                          <template v-slot:placeholder>
                            <v-layout fill-height align-center justify-center>
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-layout>
                          </template>
                        </v-img>
                      </div>
                      <ArrowButton icon="" :text="productModel.code" />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
         
          <!-- End Right Product Grid -->
        </v-row>
        <!-- cut out above -->

</template>

<style lang="scss">
@import "@/assets/sass/grid.scss";
</style>
