import { render, staticRenderFns } from "./Grid1x1.vue?vue&type=template&id=7f1ce8fb&scoped=true&"
import script from "./Grid1x1.vue?vue&type=script&lang=js&"
export * from "./Grid1x1.vue?vue&type=script&lang=js&"
import style0 from "./Grid1x1.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Grid1x1.vue?vue&type=style&index=1&id=7f1ce8fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f1ce8fb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VImg,VLayout,VProgressCircular})
