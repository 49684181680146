<script>
import { mapState, mapActions } from "vuex";
import gsap from "gsap";
import Vuetify from "vuetify";
import Grid1x1 from "@/components/grouplayouts/Grid1x1.vue";
import Grid2x2 from "@/components/grouplayouts/Grid2x2.vue";
import Grid2x6 from "@/components/grouplayouts/Grid2x6.vue";
import Grid3col from "@/components/grouplayouts/Grid3col.vue";

export default {
  name: "Group",
  props: ["id"],
  components: {
    Grid1x1,
    Grid2x2,
    Grid2x6,
    Grid3col,
  },
  data() {
    return {
      groupImage: '',
      groupColorTheme: {},
      productLayout: ''
    }
  },
  watch: {
    $route (){
      this.buildGroup();
    }
  },
  created() {
    this.buildGroup()
  },
  computed: {
    ...mapState(["currentProductGroup", "maps"]),
  },
  methods: {
    ...mapActions(["SET_BREADCRUMBS"]),
    async buildGroup(){
        await this.$store.dispatch("SET_PRODUCT_GROUP", this.id);
        this.setGroupColorTheme();
        this.init();
        this.createBreadcrumb();
        this.setGroupImage();
        this.setProductLayout();
    },
    init() {
      var t1 = new gsap.timeline();
      t1.from(".title-transition > span, .title-transition > h1", {
        x: -2000,
        scale: 0.2,
        opacity: 0,
        duration: 0.9,
        ease: "expo.inOut",
      });
      t1.from(
        ".grid-transition",
        {
          x: 2000,
          duration: 0.9,
          scale: 0.2,
          opacity: 0,
          ease: "expo.inOut",
        },
        "-=.9"
      );
    },
    createBreadcrumb() {
      //Start Breadcrumbs
      const breadcrumbs = [{ name: this.currentProductGroup.name, slug: this.currentProductGroup.name_slug, route: "productgroup" }];
      const recurse = (name) => {
        this.$store.state.maps.forEach((m) => {
          m.map_pins.forEach((p) => {
            var p_display = (((p.value.pin_link || {})[0] || {}).value || {})
              .name;
            if (p_display == name) {
              breadcrumbs.push({ name: m.name, slug: m.name_slug, route: "map" });
              recurse(m.name);
            }
          });
        });
      };

      recurse(this.currentProductGroup.name);

      this.SET_BREADCRUMBS(breadcrumbs.reverse());
      //end breadcrumbs
    },
        setGroupImage() {
      let background = "none";

      if (this.currentProductGroup && this.currentProductGroup.image.path) {
        background = `url( ${process.env.VUE_APP_CMS_BASE_URL +
          this.currentProductGroup.image.path})`;
      }
      this.groupImage = background;
    },
    setGroupColorTheme(){
      if( this.currentProductGroup && this.currentProductGroup.color_theme ){
        this.groupColorTheme = this.currentProductGroup.color_theme;
      } else {
        this.groupColorTheme = {};
      }
    },
    setProductLayout(){
       var p_layout = (((this.currentProductGroup.product_layout || {})[0] || {}).field || {})
              .name;
     this.productLayout = p_layout || '';
    }
  },
};
</script>

<template>
  <div
    class="productGrid"
    :style="{ borderColor: groupColorTheme.color1 }"
  >
    <div
      class="colorBand"
      :style="{
        backgroundImage: setGradient(groupColorTheme, 220),
      }"
    ></div>

    <v-row class="mainGrid" no-gutters>
      <!--START Grid Title -->
      <v-col
        class="gridTitle d-flex flex-column fill-height col-md-4 col-12"
        :style="{
          backgroundImage: groupImage,
          borderColor: this.groupColorTheme.color1,
        }"
      >
        <div
          class="holder title-transition"
          :style="{ borderColor: this.groupColorTheme.color1 }"
        >
          <span
            class="icon mr-4 mb-4"
            :style="{ backgroundColor: this.groupColorTheme.color1 }"
          >
            <v-icon
              x-large
              :style="{ color: this.groupColorTheme.textcolor }"
              >{{ currentProductGroup.icon }}</v-icon
            >
          </span>
          <h1
            class="headline d-inline"
            :style="{ color: this.groupColorTheme.color1 }"
          >
            {{ currentProductGroup.name }}
          </h1>
        </div>
      </v-col>
      <!--END Grid Title -->

      <!-- Grid Body -->
      <v-col class="gridBody col-12 col-md-8">
        <div
          class="gridBodyInner"
          :style="{ borderColor: this.groupColorTheme.color1 }"
        >
          <component
            :currentProductGroup="currentProductGroup"
            :is="productLayout"
          />
        </div>
      </v-col>
      <!-- END Grid Body -->
    </v-row>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/grid.scss";
</style>
